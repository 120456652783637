@import "style/index.scss";

.page-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  animation: loading 1s linear 1;

  .lds-wrap {
    margin-top: 30%;
  }

  @media (max-width: $sm) {
    .lds-wrap {
      margin-top: 80%;
    }
    .lds-spinner {
      width: 1.75rem;
      height: 1.75rem;
      margin: 2rem;
      & > div {
        transform-origin: 0.875rem 0.875rem;
        animation: lds-spinner 0.8s linear infinite;

        &::after {
          content: "";
          left: 0.8125rem;
          width: 2px;
          height: 6px;
        }
      }
    }
  }
}

.fetch-loading {
  position: absolute;
  animation: none;
  @extend .page-loading;
}

// name duaration timing-function delay iteration-count direction fill-mode

@keyframes loading {
  0% {
    opacity: 1;
    background-color: rgba($black-900, 0.6);
  }

  80% {
    opacity: 0.6;
    background-color: rgba($black-900, 0.3);
  }

  100% {
    opacity: 0;
    background-color: transparent;
    visibility: hidden;
  }
}

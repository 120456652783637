$black-800: #1d1d1b;
$black-900: #1e1e1e;

$gray-200: #d9d9d9;
$gray-250: #f5f5f5;
$gray-300: #eaeaea;
$gray-400: #8c8c8c;
$gray-350: #e6e6e6;
$gray-500: #a5a3a3;

$blue-200: #009bfa;
$blue-250: #62c3fa;
$blue-300: #0099cc;
$blue-350: #0c82aa;
$blue-400: #0c83aa;
$blue-500: #2055dc;
$blue-600: #013e99;

$red-200: #f21010;
$red-300: #c82d2d;

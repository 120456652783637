@font-face {
  font-family: "Pretendars-Black";
  font-style: normal;
  font-weith: 400;
  src: url("./woff/Pretendard-Black.woff") format("woff");
}

@font-face {
  font-family: "Pretendars-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./woff/Pretendard-Bold.woff") format("woff");
}

@font-face {
  font-family: "Pretendars-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("./woff/Pretendard-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendars-Light";
  font-style: normal;
  font-weight: 300;
  src: url("./woff/Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./woff/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./woff/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("./woff/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Pretendard-Thin";
  font-weight: 200;
  font-style: normal;
  src: url("./woff/Pretendard-Thin.woff");
}

@import "style/index.scss";

/*************************************/

.lds-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
}

.lds-spinner div {
  transform-origin: 1.5rem 1.5rem;
  animation: lds-spinner 0.8s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0.25rem;
  left: 1.4rem;
  width: 0.1875rem;
  height: 0.75rem;
  border-radius: 20%;
  // background-color: rgb(190, 194, 198);
  background-color: $black-900;
}

.medium {
  .lds-spinner div {
    transform-origin: 1.25rem 1.25rem;
    animation: lds-spinner 0.8s linear infinite;
  }
  .lds-spinner div:after {
    content: "";
    left: 1.125rem;
    width: 2px;
    height: 10px;
  }
}

.small {
  .lds-spinner {
    width: 1.75rem;
    height: 1.75rem;
    margin: 2rem;
    & > div {
      transform-origin: 0.875rem 0.875rem;
      animation: lds-spinner 0.8s linear infinite;

      &::after {
        content: "";
        left: 0.8125rem;
        width: 2px;
        height: 6px;
      }
    }
  }
}

@for $i from 1 to 11 {
  .lds-spinner > div:nth-child(#{$i}) {
    transform: rotate(36deg * $i);
    animation-delay: -1s + 0.1s * $i;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

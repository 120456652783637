@import "./color.scss";
@import "./mixin.scss";
@import "assets/fonts/font.scss";

body {
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  height: 65rem;
  word-wrap: break-word;
  word-break: keep-all;
  font-display: block;
  @media (max-width: $sm) {
    height: auto;
  }
}

section {
  max-width: 63.625rem;
  // width: 90rem;
  // height: calc(100vh - 64px);
  margin: 0 auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
  // box-shadow: 0 0.25rem 0.25rem #eaeaea;

  @media (max-width: $sm) {
    box-shadow: none;
    transition: all 0.25s ease-in-out;

    &.top {
      transform: translateY(-3.75rem);
      transition: all 0.2s ease-in-out;
    }
  }
}

h1 {
  font-family: $font-B;
  font-size: 2.25rem;
  height: 2.6875rem;

  @media (max-width: $sm) {
    font-size: 1.625rem;
    font-weight: 600;
    height: 1.9375rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-family: $font-B;

  @media (max-width: $sm) {
    font-size: 1.25rem;
    // font-weight: 600;
  }
}

h3 {
  font-size: 1.125rem;
  font-family: $font-B;

  @media (max-width: $sm) {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

h4 {
  font-size: 1rem;
  font-family: $font-B;
  @media (max-width: $sm) {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

h5 {
  font-family: $font-B;
  font-size: 0.875rem;
}

h6 {
  font-family: $font-B;
  font-size: 0.75rem;
}

button,
input {
  outline: none;
  border: none;
}

button {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  &:disabled {
    cursor: no-drop;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type="text"],
input[type="password"] {
  flex: 1;
  width: 100%;
  height: 3rem;
  padding-left: 1rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #dfe3e9;
  @include font-b(1rem);

  &::placeholder {
    @include font-b(1rem);
  }

  &:disabled {
    cursor: no-drop;
    background-color: #eff0f4;
  }

  @media (max-width: $md) {
    // height: 2.125rem;
    font-size: 0.875rem;
  }
  &[role="modal"] {
    height: 2.5rem;
  }
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] {
  & + label {
    font-size: 0.875rem;
    color: $black-900;
    @include start-center;

    @media (max-width: $sm) {
      font-size: 0.75rem;
    }
  }
  // & + label[for="all"] {
  //   font-size: 1rem;
  //   @media (max-width: $sm) {
  //     font-size: 0.875rem;
  //   }
  // }

  & + label[role="dflt"] {
    position: relative;

    &::before {
      display: inline-flex;
      content: "";
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border-radius: 0.125rem;
      border: 0.125rem solid $black-900;
    }
  }

  &:checked + label[role="dflt"] {
    &::before {
      display: inline-flex;
      content: "";
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border-radius: 0.125rem;
      border: 0.125rem solid $black-900;
    }

    &::after {
      position: absolute;
      @include pseudo("icon_check-gray.svg", 1rem, 1rem);
      background-position: 0px, 2px;
    }
  }

  & + label[role="box"] {
    position: relative;
    column-gap: 0.5rem;

    &.blue {
      &::before {
        border-color: $blue-300;
      }
    }

    &::before {
      display: flex;
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      border: 0.125rem solid $black-900;
      box-sizing: border-box;
    }
  }
  &:checked + label[role="box"] {
    &::before {
      width: 1.25rem;
      height: 1.25rem;
      border: none;
      border: 0.125rem solid $blue-300;
      background-color: $blue-300;
    }
    &::after {
      position: absolute;
      left: 0px;
      display: inline-block;
      content: "";
      width: 1.125rem;
      height: 0.875rem;
      background: url("/assets/icon/icon_white_check.svg") no-repeat;
      background-position: 4px 2px;
    }
  }

  & + label[role="sm-box"] {
    @extend [role="box"];
    &::before {
      display: inline-flex;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border-radius: 0.125rem;
    }
  }
  &:checked + label[role="sm-box"] {
    &::before {
      width: 1rem;
      height: 1rem;
      background-color: $blue-300;
    }
    &::after {
      position: absolute;
      left: 0px;
      @include pseudo("icon_white_check.svg", 0.875rem, 0.75rem);
      background-position: 2px 2px;
    }
  }

  & + label[role="check-icon"] {
    &::before {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
      @include pseudo("icon_check-gray.svg", 1.25rem, 1.25rem);
    }
  }
  &:checked + label[role="check-icon"] {
    &::before {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
      @include pseudo("icon_check-blue.svg", 1.25rem, 1.25rem);
    }
  }
}

input[type="file"] {
  display: none;
  width: 100%;
}

label {
  display: inline-block;
  cursor: pointer;

  &[role="dot"] {
    font-size: 0.75rem;
    color: $gray-400;
    &::before {
      display: inline-flex;
      content: "*";
      padding-right: 0.25rem;
      color: $blue-300;
    }
  }
  @media (max-width: $md) {
    font-size: 0.0625rem;
  }
}

span {
  display: inline-block;
}

.main-content {
  max-width: $max-width;
  min-height: 35.625rem;
  height: 100%;
  padding-top: 6.625rem;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
  position: relative;
  // overflow-y: auto;

  h1 {
    margin-bottom: 0.5rem;
  }

  .title-dscript {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: $blue-300;
  }

  @media (max-width: $sm) {
    padding: 0 1rem;
    margin-top: 4.625rem;
    min-height: auto;
  }
}

.card {
  padding: 1.25rem 1.8125rem 1.8125rem;
  border-radius: 0.75rem;
  background-color: #fff;
  text-align: center;
  border: 0.0625rem solid transparent;
  &:hover {
    border-color: $blue-300;
  }
}

.btn-small {
  // min-width: 4.0625rem;
  height: 3rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  background-color: $blue-300;
  font-weight: bold;
  color: #fff;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: $blue-400;
    transition: all 0.2s ease-in;
  }
  &:disabled {
    background-color: $gray-400;
  }
  @media (max-width: $sm) {
    height: 2.75rem;
  }
}

.btn-cancel {
  border: 0.0625rem solid rgba($blue-300, 0.4);
  color: $blue-300;
  background-color: #fff;

  @extend .btn-small;

  &:hover {
    border: 0.0625rem solid rgba($blue-300, 1);
    color: $blue-400;
    background-color: #fff;
    transition: all 0.2s ease-in;
  }

  @media (max-width: $sm) {
    height: 2.75rem;
  }
}

.btn-full {
  width: 12rem;
  height: 2.75rem;
  border-radius: 1.375rem;
  background-color: $blue-300;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: $blue-400;
    transition: all 0.2s ease-in;
  }
  &:disabled {
    background-color: $gray-400;
  }
  @media (max-width: $sm) {
    height: 2.75rem;
  }
}

.btn-modal {
  width: 7.25rem;
  margin-top: 1.25rem;
  @extend .btn-full;

  &.cancel {
    height: 2.75rem;
    border-radius: 1.375rem;
    @extend .btn-cancel;
  }
}

.btn-login {
  width: 100%;
  height: 4rem;
  margin-bottom: 1.5rem;
  border-radius: 2rem;
  @include font-b(1.25rem);
  @extend .btn-full;
  // @include boxShadow;

  @media (max-width: $sm) {
    margin-bottom: 1rem;
    height: 3.125rem;
    @include font-b(1rem);
  }
}

.btn-arr-nxt {
  position: absolute;
  top: 50%;
  right: -3.75rem;
  width: 2.75rem;
  height: 2.75rem;
  transform: translateY(-50%);
  &::after {
    @include pseudo("icon_btn_cal_next.svg", 2.75rem, 2.75rem);
  }

  &:disabled {
    &::after {
      transform: rotate(180deg);
      @include pseudo("icon_btn_prev_disabled.svg", 2.75rem, 2.75rem);
    }
  }

  @media (max-width: $sm) {
    display: none;
  }
}
.btn-arr-pre {
  position: absolute;
  top: 50%;
  left: -3.75rem;
  width: 2.75rem;
  height: 2.75rem;
  transform: translateY(-50%);
  &::after {
    @include pseudo("icon_btn_cal_prev.svg", 2.75rem, 2.75rem);
  }

  &:disabled {
    &::after {
      @include pseudo("icon_btn_prev_disabled.svg", 2.75rem, 2.75rem);
    }
  }

  @media (max-width: $sm) {
    display: none;
  }
}

.login-btn-wrap {
  margin-top: 2rem;

  @media (max-width: $sm) {
    margin-top: 1.5rem;
  }
  button {
    position: relative;
    width: 100%;
    height: 4rem;
    padding: 0 2.5rem;
    margin-bottom: 0.625rem;
    border-radius: 2rem;
    color: #fff;
    @include font-b(1.25rem);
    @include start-center;
    // @include boxShadow;

    &::after {
      position: absolute;
      right: 2.5rem;
      @include pseudo("icon_white_arrow.svg", 1.5rem, 1.5rem);
      @media (max-width: $sm) {
        right: 1.375rem;
        @include pseudo("icon_white_arrow.svg", 1.25rem, 1.25rem);
      }
    }

    @media (max-width: $sm) {
      height: 3.125rem;
      padding: 0 1.375rem;
      font-size: 1rem;
    }
  }

  .btn-naver {
    background-color: #08b041;
    &::before {
      margin-right: 0.75rem;
      @include pseudo("icon_naver.svg", 1.125rem, 1.125rem);
    }
  }

  .btn-kakao {
    background-color: #f8c308;
    &::before {
      margin-right: 0.625rem;
      @include pseudo("icon_kakao.svg", 1.5rem, 1.5rem);
    }
    &::after {
      @include pseudo("icon_black_arrow.svg", 1.5rem, 1.5rem);
    }
  }

  .btn-facebook {
    background-color: $blue-600;

    &::before {
      margin-right: 0.75rem;
      @include pseudo("icon_facebook.svg", 1.5rem, 1.5rem);
    }
  }
  .btn-apple {
    background-color: $black-900;
    &::before {
      margin-right: 0.75rem;
      @include pseudo("icon_apple.svg", 1.5rem, 1.25rem);
    }
  }
  .btn-google {
    margin-bottom: 2rem;
    color: $black-800;
    background-color: $gray-350;
    &::before {
      margin-right: 0.75rem;
      @include pseudo("icon_google.svg", 1.5rem, 1.5rem);
    }
    &::after {
      @include pseudo("icon_black_arrow.svg", 1.5rem, 1.5rem);
      @media screen {
        @include pseudo("icon_black_arrow.svg", 1.25rem, 1.25rem);
      }
    }
  }
}

.book-btn {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  background-color: #fff;
  font-size: 0.875rem;
  color: $blue-300;
  border: 0.0625rem solid transparent;

  @include center;

  &:hover {
    border-color: $blue-300;
  }
}

.modal-content {
  .detail {
    margin-top: 0.5rem;
    font-size: 0.75rem;
  }
}

.modal-form {
  width: auto;
  padding: 1.5rem 2.5rem;
  margin: 0 -1rem;
  background-color: rgba($blue-200, 0.2);
  input[type="text"],
  input[type="password"] {
    height: 2.5rem;
    font-weight: bold;
    margin-bottom: 0rem;
    &::placeholder {
      font-size: 0.75rem;
      color: $gray-400;
    }
    &:focus {
      outline: 1px solid $blue-300;
    }
  }
  .reg-chk {
    width: 100%;
    margin: 0.5rem 0 1.5rem;
    font-size: 0.75rem;
    text-align: left;
    & > span {
      margin-left: 0.75rem;
      color: $gray-400;

      &::after {
        @include pseudo("icon_check_small.svg", 1rem, 1rem);
        background-position: 2px 2px;
      }

      &.on {
        color: $blue-300;
        &::after {
          @include pseudo("icon_check_blue_small.svg", 1rem, 1rem);
          background-position: 2px 2px;
        }
      }
    }
  }
}

.mb-cont-wrap {
  background-color: $gray-250;
  width: 100%;
  padding: 0;
  margin: -1rem auto 0;

  border-radius: 0;

  button {
    height: 3.125rem;
    font-size: 1rem;
  }
}

.no-data {
  position: relative;

  &::before {
    @include pseudo("icon_nolist.svg", 10rem, 10rem);
  }

  &::after {
    position: absolute;
    top: 11.5rem;
    left: 0;
    width: 100%;
    content: attr(data-no);
    font-size: 0.875rem;
    color: rgba($gray-400, 0.8);

    @media (max-width: $sm) {
      top: 10rem;
    }
  }
}

.user-info {
  width: 100%;
  text-align: left;

  div {
    // margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    column-gap: 0.5rem;
  }
  label {
    font-size: 0.75rem;
    color: $gray-400;
    margin-bottom: 0.5rem;
  }

  textarea {
    width: 100%;
    height: 8.125rem;
    padding: 0.875rem 1rem;
    border-radius: 0.25rem;
    outline: none;
    border: 0.0625rem solid rgba($gray-400, 0.4);
    resize: none;
    word-wrap: break-word;
    word-break: keep-all;
  }

  .btn-spl-join {
    width: auto;
    padding: 0 2rem;
    height: 3rem;
    @extend .btn-small;
    @include center;

    @media (max-width: $sm) {
      width: 100%;
      margin: 0;
    }

    &::after {
      margin-left: 0.5rem;
      @include pseudo("icon_home_go_arrow.svg", 1.125rem, 1.125rem);
    }
  }

  .select-wrap {
    margin-bottom: 0;
  }

  .phone-select-wrap {
    margin-bottom: 0;
  }
}

.toggle {
  padding-top: 0.5625rem;
  @include between-center;

  &.brd {
    padding: 1.375rem 1rem;
    border-bottom: 0.0625rem dashed $gray-500;
  }

  h4 {
    @include center;
    &[role="apple"] {
      &::before {
        margin-right: 0.5rem;
        @include pseudo("icon_logo_apple.svg", 1.5rem, 1.5rem);
      }
    }
    &[role="gmail"] {
      &::before {
        margin-right: 0.5rem;
        @include pseudo("icon_logo_google.svg", 1.5rem, 1.5rem);
      }
    }
  }

  input[type="checkbox"] {
    & + label {
      width: 3.75rem;
      height: 1.75rem;
      margin: 0;
      border-radius: 0.875rem;
      border: 0.0625rem solid $gray-200;
      background-color: $gray-200;

      &::before {
        display: inline-flex;
        content: "";
        width: 1.375rem;
        height: 1.375rem;
        margin: 0.125rem;
        border-radius: 0.6875rem;
        background-color: #fff;
        transition: all 0.25s ease-in;
      }
    }

    &:checked + label {
      border-color: $blue-300;
      background-color: $blue-300;

      &::before {
        transform: translate(2rem, 0);
        transition: all 0.25s ease-in;
      }
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  max-width: 100%;
  @media (max-width: 31.25rem) {
    /* height: 100%; */
    height: calc(var(--vh, 1vh) * 100);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  font-family: "Pretendard-Regular";
  font-size: 1rem;
  color: #1e1e1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

table {
  width: 100%;
}

$lg: 75rem;
$md: 769px;
$sm: 500px;

$font-B: "Pretendars-Bold";
$font-EB: "Pretendars-ExtraBold";
$font-L: "Pretendars-Light";
$font-M: "Pretendars-Medium";
$font-R: "Pretendard-Regular";
$font-RL: "Pretendard-SemiBold";
$font-T: "Pretendard-Thin";

$font-s-xs: 0.0625rem;
$font-s-s: 0.75rem;
$font-s-m: 0.875rem;
$font-s-r: 1rem;
$font-s-l: 1.125rem;
$font-s-xl: 1.25rem;
$font-s-2xl: 1.5rem;
$font-s-3xl: 1.75rem;

$max-width: 63.625rem;

@mixin font-b($fontSize) {
  font-family: $font-B;
  font-size: $fontSize;
}

@mixin font-m($fontSize) {
  font-family: $font-M;
  font-size: $fontSize;
}

.desc {
  font-family: $font-R;
  font-size: 0.75rem;
  color: $blue-300;
  @media (max-width: $sm) {
    font-size: 0.75rem;
  }
}

.flex {
  display: flex;
}

.justify-center {
  display: flex;
  align-items: center;
}

@mixin between-center {
  @extend .justify-center;
  justify-content: space-between;
}

@mixin center {
  @extend .justify-center;
  justify-content: center;
}

@mixin start-center {
  @extend .justify-center;
  justify-content: flex-start;
}

@mixin end-center {
  @extend .justify-center;
  justify-content: flex-end;
}

@mixin transition2 {
  transition: all 0.3s ease-in-out;
}

@mixin noscroll {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin boxShadow($color) {
  box-shadow: 0 0.9375rem 1rem $color;
}

@mixin transition2 {
  transition: all 0.2s ease-in-out;
}

@mixin transition2_5 {
  transition: all 0.25s ease-in-out;
}

@mixin pseudo($url, $size, $bgsize, $bg: "transparent") {
  display: inline-block;
  content: "";
  width: $size;
  height: $size;
  background: url("/assets/icon/" + $url) no-repeat;
  background-size: $bgsize;
}

@mixin boxShadow {
  box-shadow: 0 0.25rem 0.25rem $gray-400;
}

@mixin borderRadius($size) {
  border-radius: $size;
}

@mixin fixed($zIndex: 99) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zIndex;
}
